import React, { useState } from "react";
import { Box, Collapse, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { bakeStreetLogo, STORAGE_BUCKET } from "./constants";
import { getDeviceToken } from "../../firebase";
import bkstApi from "./api";
import QuickSearch from "./header/QuickSearch";

const drawerWidth = 240;

const muiListStyle = {
  color: "inherit",
  "&:hover": {
    color: "#202138",
    backgroundColor: "#EEE"
  },
  "& .MuiListItemIcon-root": {
    color: "inherit",
    minWidth: "36px"
  }
};
const muiListNestedStyle = {
  paddingLeft: "4rem",
  color: "inherit",
  "&:hover": {
    backgroundColor: "#EEE"
  }
};

export default function ResponsiveDrawer(props) {
  const container = window !== undefined ? () => document.body : undefined;

  const [showAdminSearchMenu, setShowAdminSearchMenu] = useState(false);

  const handleAdminSearchMenuClick = (e) => {
    e.stopPropagation();
    setShowAdminSearchMenu(!showAdminSearchMenu);
  };

  const enablePushNotifications = async (e) => {
    e.preventDefault();
    const token = await getDeviceToken();
    if (token) {
      bkstApi.post(`/fcm`, { token });
    }
  };

  const drawer = (
    <div>
      <div>
        <List>
          <ListItem component={RouterLink} to="/my/quote" sx={{ justifyContent: "center" }}>
            <img
              alt="logo"
              style={{ width: "110px", padding: "2px 3px 8px 3px", borderRadius: "5px", backgroundColor: "#EEE" }}
              src={bakeStreetLogo}
            ></img>
          </ListItem>
        </List>
      </div>
      <List>
        <ListItem component={RouterLink} to="/request" sx={muiListStyle}>
          <ListItemIcon>
            <LocalAtmOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={`Quotes`} />
        </ListItem>
        <ListItem component={RouterLink} to="/order" sx={muiListStyle}>
          <ListItemIcon>
            <LocalMallOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={`Orders`} />
        </ListItem>
        {/* <ListItem component={RouterLink} to="/report" sx={muiListStyle}>
          <ListItemIcon>
            <AssessmentOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={`Reports`} />
        </ListItem> */}
        <ListItem component={RouterLink} to="/loan" sx={muiListStyle}>
          <ListItemIcon>
            <AccountBalanceOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={`Loan`} />
        </ListItem>
        <ListItem component={RouterLink} to="/login/reps" sx={muiListStyle}>
          <ListItemIcon>
            <PeopleAltOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={`Logins`} />
        </ListItem>
        <ListItem button onClick={handleAdminSearchMenuClick} sx={muiListStyle}>
          <ListItemIcon>
            <SearchOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Search" />
          {showAdminSearchMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={showAdminSearchMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem component={RouterLink} to="/baker/search" sx={muiListNestedStyle}>
              <ListItemText primary={`Bakers`} />
            </ListItem>
            <ListItem component={RouterLink} to="/order/search" sx={muiListNestedStyle}>
              <ListItemText primary={`Orders`} />
            </ListItem>
            <ListItem component={RouterLink} to="/product/search" sx={muiListNestedStyle}>
              <ListItemText primary={`Photos`} />
            </ListItem>
            <ListItem component={RouterLink} to="/request/search" sx={muiListNestedStyle}>
              <ListItemText primary={`Requests`} />
            </ListItem>
          </List>
        </Collapse>
        <Box px={2} pt={10} align="center">
          <Box mb={1}>
            <Typography variant="overline" onClick={enablePushNotifications}>
              {process.env.REACT_APP_VERSION}
            </Typography>
          </Box>
          <img style={{ width: "100%", borderRadius: "50%" }} src={`${STORAGE_BUCKET}/img/h/mascot.gif`} />
          <QuickSearch />
        </Box>
      </List>
    </div>
  );

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
      {" "}
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={"left"}
          open={props.open}
          onClose={props.toggle}
          onClick={props.toggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, backgroundColor: "#202138", color: "#FFFFFF" }
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </Box>
  );
}
