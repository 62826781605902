import React, { useState, useEffect } from "react";
import { Box, Container, Grid, LinearProgress, Typography, Avatar, Paper } from "@mui/material";
import bkstApi from "../../common/component/api";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { STORAGE_BUCKET } from "../../common/component/constants";

const FILTERS = ["recent", "upcoming", "open"];

export default function Dashboard(props) {
  const [filter, setFilter] = useState("recent");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [data, setData] = useState([]);

  const [orders, setOrders] = useState([]);
  const [summary, setSummary] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let from = "";
    let to = "";

    if (filter === "recent") {
      from = moment().subtract(1, "days").format("YYYY-MM-DD");
    } else if (filter === "open") {
    } else if (filter === "upcoming") {
      from = moment().format("YYYY-MM-DD");
      to = moment().add(2, "days").format("YYYY-MM-DD");
    } else {
      from = moment().format("YYYY-MM-DD");
      to = moment().add(28, "days").endOf("isoweek").format("YYYY-MM-DD");
    }

    setFromDt(from);
    setToDt(to);

    search(from, to);
  }, [filter]);

  const search = (from, to) => {
    setLoading(true);

    bkstApi.get(`/admin/bakers?status=onboard`).then((res) => {
      setData(res.data.sort((o1, o2) => o1.bizName.localeCompare(o2.bizName)));
      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box my={7}>
        <Container>
          <Box mb={2}>
            <Typography variant="h1">{data.length} 🚀</Typography>
          </Box>
          <Grid container spacing={3}>
            {data.map((o) => (
              <Grid item xs={12} sm={3}>
                <Paper elevation={0} component={RouterLink} to={`/baker/${o.id}`} sx={{ textDecoration: "none" }}>
                  <Box p={2} textAlign={"center"} sx={{ border: "1px solid #ECECEC", backgroundColor: "#FFF" }}>
                    <Box mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                      <Avatar src={`${STORAGE_BUCKET}/img/b/${o.id}/logo400.webp`} sx={{ width: "60px", height: "60px" }} />
                    </Box>
                    <Typography variant="caption">{o.bizName}</Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Box mt={15} textAlign={"center"}>
            <Typography variant="h6">Test Accounts</Typography>
            <Box mt={2} sx={{ display: "flex", gap: "20px", justifyContent: "center", alignItems: "center" }}>
              <Avatar
                sx={{ width: "60px", height: "60px" }}
                src={`${STORAGE_BUCKET}/img/b/-MVIf1diaYOCm5KynVDF/logo400.webp`}
                component={RouterLink}
                to={`/baker/-MVIf1diaYOCm5KynVDF`}
              />
              <Avatar
                sx={{ width: "60px", height: "60px" }}
                src={`${STORAGE_BUCKET}/img/b/-MX2oaNrh4TcKVvnhcT9/logo400.webp`}
                component={RouterLink}
                to={`/baker/-MX2oaNrh4TcKVvnhcT9`}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    );
  }
}
