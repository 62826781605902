import React, { useState } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import SendIcon from "@mui/icons-material/Send";
import bkstApi from "../../common/component/api";
import CustomerDetailEntry from "../../customer/component/CustomerDetailEntry";

export default function OrderFormReader(props) {
  const [open, setOpen] = useState(false);

  const [image, setImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/tmp%2F1736966907951-PXL_20250115_165502821.MP.jpg?alt=media&token=0458c1de-f24e-4d79-911f-0efe3c21e056"
  );

  const [prompt, setPrompt] = useState(
    "Extract text from the image using OCR. Create a json with the following fields: Name, Date Ordered, Date Needed, Phone, Taken by, Inscription, Pickup or Deliver, Day / Time, Total. Also include a field with all the raw text."
  );
  const [response, setResponse] = useState("");

  const [customer, setCustomer] = useState("");

  const [loading, setLoading] = useState(false);

  const sendToGemini = () => {
    setLoading(true);
    bkstApi.post(`/gemini`, { img: image, prompt }).then((res) => {
      setResponse(JSON.parse(res.data.response));

      convertOcrResponse(JSON.parse(res.data.response));

      setLoading(false);
    });
  };

  const convertOcrResponse = (response) => {  
    let customer = {}
    const nameTokens = response["Name"].trim().split(" ");
    customer.firstName = nameTokens[0];
    if (nameTokens.length > 1) {
      customer.lastName = nameTokens[nameTokens.length - 1];
    }
    customer.phone = response["Phone"].replace(/\D/g,'');

    setCustomer(customer);
  }

  const createOrder = () => {
    window.alert("Order created");
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)}>
        <TipsAndUpdatesOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>Order Form Scanner</DialogTitle>
        {loading && <LinearProgress />}
        <DialogContent>
          <Box pt={2}>
            <Grid container spacing={4}>
              <Grid item xs={5}>
                <img src={image} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={7}>
                <Box px={2} pt={2} pb={1} mb={4} sx={{ backgroundColor: "#ECECEC" }}>
                  <TextField
                    id="prompt"
                    value={prompt}
                    name="prompt"
                    variant="outlined"
                    rows={3}
                    fullWidth
                    required
                    autoFocus
                    placeholder="Type something"
                    onChange={(e) => setPrompt(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton size="small" color="primary" variant="contained" disabled={prompt.length === 0} onClick={sendToGemini}>
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        paddingRight: 0
                      },
                      backgroundColor: "#FFFFFF"
                    }}
                  />
                </Box>
                <form onSubmit={createOrder}>
                  <Box sx={{ backgroundColor: "#FFFFFF" }}>
                    <Typography variant="body2">{JSON.stringify(response)}</Typography>
                    <CustomerDetailEntry
                      customer={customer}
                      onChange={(o) => {
                        setCustomer(o);
                      }}
                    />
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
