import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Container, Link, LinearProgress, Tab, Tabs, Paper, Typography, Avatar } from "@mui/material";
import bkstApi from "../../common/component/api";
import { formatPhoneNumber } from "../../common/component/util";
import OrderSummary from "../../order/component/OrderSummary";
import QuoteSummary from "../../quote/component/QuoteSummary";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";

export default function Customer(props) {
  const { siteId, customerId } = useParams();

  const [customer, setCustomer] = useState("");
  const [loading, setLoading] = useState(true);

  const [tab, setTab] = useState(0);

  useEffect(() => {
    bkstApi(`/admin/site/${siteId}/customer/${customerId}`).then((res) => {
      setCustomer(res.data.customer);
      setLoading(false);
    });
  }, [customerId]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={6}>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Link underline="none" component={RouterLink} to={`/baker/${siteId}`} href="#">
              <Avatar src={`${BAKER_PIC_FOLDER}/${siteId}/logo400.webp`} sx={{ width: "60px", height: "60px" }} />
            </Link>
            <Box>
              <Typography variant="h4">
                {customer.firstName} {customer.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                since {moment(customer.created).format("MMM, YYYY")}
              </Typography>
              {customer.summary && (
                <Typography variant="overline">
                  ORDERS: {customer.summary.orders.future} FUTURE · {customer.summary.orders.past} PAST | QUOTES: {customer.summary.quotes.future}{" "}
                  FUTURE · {customer.summary.quotes.past} PAST
                </Typography>
              )}
            </Box>
          </Box>
          <Box my={2}>
            <Tabs value={tab}>
              <Tab key={0} label={"Profile"} onClick={() => setTab(0)} />
              {customer.phone && <Tab key={1} label={"Orders"} onClick={() => setTab(1)} />}
              {customer.phone && <Tab key={2} label={"Quotes"} onClick={() => setTab(2)} />}
            </Tabs>
          </Box>
          {tab === 0 && (
            <Box>
              <Paper elevation={0}>
                <Box p={2}>
                  <Box>
                    <Typography variant="overline" color="textSecondary">
                      First Name
                    </Typography>
                    <Typography variant="body1">{customer.firstName}</Typography>
                  </Box>
                  <Box my={2}>
                    <Typography variant="overline" color="textSecondary">
                      Last Name
                    </Typography>
                    <Typography variant="body1">{customer.lastName}</Typography>
                  </Box>
                  {customer?.level && (
                    <Box my={2}>
                      <Typography variant="overline" color="textSecondary">
                        LOYALTY STATUS
                      </Typography>
                      <Typography variant="body1">{customer?.level}</Typography>
                    </Box>
                  )}
                  <Box my={2}>
                    <Typography variant="overline" color="textSecondary">
                      Phone
                    </Typography>
                    {customer.phone && <Typography variant="body1">{formatPhoneNumber(customer.phone)}</Typography>}
                  </Box>
                  <Box my={2}>
                    <Typography variant="overline" color="textSecondary">
                      Email
                    </Typography>
                    {customer.email && <Typography variant="body1">{customer.email}</Typography>}
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}
          {tab === 1 && (
            <Box>
              {customer.orders
                ?.sort((o1, o2) => o2.fulfillment.date.localeCompare(o1.fulfillment.date))
                .map((order) => {
                  return (
                    <Link key={order.id} underline="none" component={RouterLink} to={`/order/${order.id}`} href="#">
                      <OrderSummary key={order.id} order={order} />
                    </Link>
                  )
                })}
            </Box>
          )}
          {tab === 2 && (
            <Box>
              {customer.quotes
                ?.sort((o1, o2) => o2.date.localeCompare(o1.date))
                .map((request, idx) => {
                  return (
                    <Link key={request.id} underline="none" component={RouterLink} to={`/request/${request.id}`} href="#">
                      <QuoteSummary key={idx} request={request} />
                    </Link>
                  )
                })}
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "15px" }}>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            fullWidth
            target="_blank"
            href={`https://dashboard.stripe.com/customers/${customer.cid}`}
            disabled={!customer.cid}
          >
            View in Stripe
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="secondary"
            fullWidth
            target="_blank"
            href={`https://console.firebase.google.com/u/0/project/bakestreet-f55a0/firestore/databases/-default-/data/~2Fbaker~2F${siteId}~2Fcustomer~2F${customerId}`}
          >
            View in DB
          </Button>
        </Box>
      </Container>
    );
  }
}
