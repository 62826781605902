import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, Box, CircularProgress, Grid, Link, InputAdornment, TextField, Typography, Avatar } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import bkstApi from "../api";
import { Link as RouterLink } from "react-router-dom";
import { BAKER_PIC_FOLDER } from "../constants";
import { formatPhoneNumber } from "../util";
import moment from "moment";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";

export default function Lookup() {
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    useEffect(() => {
      // Cleanup the previous timeout on re-render
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const debouncedCallback = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };

    return debouncedCallback;
  };

  const handleSearch = useDebounce((query) => {
    callApi(query);
  }, 1000);

  const callApi = () => {
    bkstApi(`/admin/lookup?q=${q}`).then((res) => {
      const results = res.data;
      setOptions(results);
      setLoading(false);
    });
  };

  const getSummary = (o) => {
    let summary = "";
    if (+o.orders?.future > 0) {
      summary = `${+o.orders.future} upcoming order${+o.orders.future > 1 ? "s" : ""}`;
    } else if (+o.orders?.past > 0) {
      summary = `${+o.orders.past} past order${+o.orders.past > 1 ? "s" : ""}`;
    } else if (+o.quotes?.future > 0) {
      summary = `${+o.quotes.future} future quote${+o.quotes.future > 1 ? "s" : ""}`;
    } else if (+o.quotes?.past > 0) {
      summary = `${+o.quotes.past} past quote${+o.quotes.past > 1 ? "s" : ""}`;
    }

    return summary;
  };

  const handleClick = () => {
    setQ("");
  };

  useEffect(() => {
    if (q.length > 1) {
      setLoading(true);
      handleSearch(q);
    } else {
      setOptions([]);
    }
  }, [q]);

  const getListItem = (obj, option) => {
    const orderCount = +option.summary?.orders?.past + +option.summary?.orders?.future;
    const futureQuotesCount = +option.summary?.orders?.future;

    let summary = (
      <Box>
        <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em" }}>
          {option.firstName} {option.lastName}
        </Typography>
        <Typography variant="overline" display="block" color="textSecondary">
          {formatPhoneNumber(option.phone) || ""}
        </Typography>
        {orderCount > 0 && (
          <Typography variant="overline" color="textSecondary" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <ShoppingBagOutlinedIcon sx={{ fontSize: "16px", color: "#999" }} />
            {orderCount} Orders {+option.summary.orders.future > 0 ? ` (${option.summary.orders.future} upcoming)` : ""}
          </Typography>
        )}
        {futureQuotesCount > 0 && (
          <Typography variant="overline" color="textSecondary" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <LocalAtmOutlinedIcon sx={{ fontSize: "16px", color: "#999" }} />
            {futureQuotesCount} Quotes
          </Typography>
        )}
        <Typography variant="caption" color="textSecondary" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <AccessTimeOutlinedIcon sx={{ fontSize: "14px", color: "#999" }} />
          {moment(option.refreshed).fromNow()}
        </Typography>
      </Box>
    );

    return (
      <Link
        key={option.objectID}
        component={RouterLink}
        to={`/site/${option.siteId}/customer/${option.customerId}`}
        style={{ textDecoration: "none", color: "inherit" }}
        onClick={handleClick}
      >
        <Box p={1}>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item align="center">
              <img src={`${BAKER_PIC_FOLDER}/${option.siteId}/logo400.webp`} style={{ backgroundColor: "#fff", width: "40px", height: "40px" }} />
            </Grid>
            <Grid item>{summary}</Grid>
          </Grid>
        </Box>
      </Link>
    );
  };

  return (
    <Autocomplete
      id="quick-search"
      freeSolo
      noOptionsText={"No matches found"}
      style={{ maxWidth: "250px", flexGrow: "1" }}
      filterOptions={(x) => x}
      renderOption={getListItem}
      getOptionLabel={(option) => option.title}
      options={options}
      disableClearable
      blurOnSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          value={q}
          variant="outlined"
          size="small"
          onChange={(e) => setQ(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon style={{ color: "#888" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
